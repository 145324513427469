import types from 'store/ad-management/types';
import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';

const getList = () => helper.createAction(types.FETCH);
const setDataTable = (detail, count, remain, changedToLastPage) =>
  helper.createAction(types.SET_DATA_TABLE, {
    detail,
    count,
    remain,
    changedToLastPage,
  });
const setErrorTable = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERROR_TABLE, { errors }, scope);
const setStatusTable = (status) =>
  helper.createAction(types.SET_STATUS_TABLE, { status });

const create = (data) => helper.createAction(types.CREATE, { data });
const update = (id, data) => helper.createAction(types.UPDATE, { id, data });
const deleteAds = (ids) => helper.createAction(types.DELETE, { ids });
const dropAds = (ids) => helper.createAction(types.DROP, { ids });
const restore = (ids) => helper.createAction(types.RESTORE, { ids });

const getOne = (id, callback = () => {}) =>
  helper.createAction(types.GET, { id, callback });
const setDetail = (data) => helper.createAction(types.SET_DETAIL, { data });
const setErrorDetail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);
const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, { status });

const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });
const updatePage = (page) => helper.createAction(types.UPDATE_PAGE, { page });

const uploadCsv = (file) => helper.createAction(types.UPLOAD_CSV, { file });
const csvValidationSucceed = (data) =>
  helper.createAction(types.CSV_VALIDATION_SUCCEED, { data });
const csvValidationErrors = (errors, scope) =>
  helper.createAction(types.CSV_VALIDATION_ERRORS, { errors }, scope);
const clearCsvUploadData = () =>
  helper.createAction(types.CLEAR_CSV_UPLOAD_DATA);
const setCsvFilename = (filename) =>
  helper.createAction(types.SET_CSV_FILENAME, { filename });
const setCsvOriginFilename = (originFilename) =>
  helper.createAction(types.SET_CSV_ORIGIN_FILENAME, { originFilename });
const submitCsv = () => helper.createAction(types.SUBMIT_CSV);

const uploadCsvDelete = (file) =>
  helper.createAction(types.UPLOAD_CSV_DELETE, { file });
const submitCsvDelete = () => helper.createAction(types.SUBMIT_CSV_DELETE);

const setStatusCsvUpload = (status) =>
  helper.createAction(types.SET_STATUS_CSV_UPLOAD, { status });

const deleteByFilters = (excludedIds) =>
  helper.createAction(types.DELETE_BY_FILTERS, { excludedIds });
const dropByFilters = (excludedIds) =>
  helper.createAction(types.DROP_BY_FILTERS, { excludedIds });
const restoreByFilters = (excludedIds) =>
  helper.createAction(types.RESTORE_BY_FILTERS, { excludedIds });
const changeTab = (tab) => helper.createAction(types.CHANGE_TAB, { tab });

const downloadCsv = () => helper.createAction(types.DOWNLOAD_CSV);

// Masterdata search
const searchMedia = (searchTerm, selected, agencyId) =>
  helper.createAction(types.SEARCH_MEDIA, { searchTerm, selected, agencyId });
const searchAdGroup1 = (searchTerm, selected, agencyId) =>
  helper.createAction(types.SEARCH_AD_GROUP1, {
    searchTerm,
    selected,
    agencyId,
  });
const searchAdGroup2 = (searchTerm, selected, agencyId) =>
  helper.createAction(types.SEARCH_AD_GROUP2, {
    searchTerm,
    selected,
    agencyId,
  });

const searchMediaAccount = (searchTerm, selected, agencyId) =>
  helper.createAction(types.SEARCH_MEDIA_ACCOUNT, {
    searchTerm,
    selected,
    agencyId,
  });

const searchMediaSideCampaign = (searchTerm, selected, agencyId) =>
  helper.createAction(types.SEARCH_MEDIA_SIDE_CAMPAIGN, {
    searchTerm,
    selected,
    agencyId,
  });

const searchMediaSideGroup = (searchTerm, selected, agencyId) =>
  helper.createAction(types.SEARCH_MEDIA_SIDE_GROUP, {
    searchTerm,
    selected,
    agencyId,
  });

const fetchMasterdataByAgency = (agencyId) =>
  helper.createAction(types.FETCH_MASTERDATA_BY_AGENCY, { agencyId });

const cleanStates = (tab) => helper.createAction(types.CLEAN_STATES, { tab });

const changeAd = (data, type, isSelectedAllPages) =>
  helper.createAction(types.AD_CHANGE, { data, type, isSelectedAllPages });

const changeAdByFilter = (data, type, isSelectedAllPages) =>
  helper.createAction(types.AD_CHANGE_BY_FILTERS, {
    data,
    type,
    isSelectedAllPages,
  });

const checkContractedLpoBefore = () =>
  helper.createAction(types.CONTRACTED_LPO_BEFORE);
const setLpoDataCount = (count) =>
  helper.createAction(types.SET_LPO_DATA_COUNT, count);

// RegistrationStatus
const getDataRegistrationStatus = () =>
  helper.createAction(types.GET_DATA_REGISTRATION_STATUS);
const setDataRegistrationStatus = (data) =>
  helper.createAction(types.SET_DATA_REGISTRATION_STATUS, { data });
const setStatusGetRegistrationStatus = (status) =>
  helper.createAction(types.SET_STATUS_GET_REGISTRATION_STATUS, { status });
const cancelRegistrationStatus = (id, status, callback = () => {}) =>
  helper.createAction(types.CANCEL_REGISTRATION_STATUS, {
    id,
    status,
    callback,
  });
const setOpenRegistrationStatus = (status) =>
  helper.createAction(types.SET_OPEN_REGISTRATION_STATUS, { status });

const adManagementActions = {
  getList,
  setDataTable,
  create,
  update,
  delete: deleteAds,
  drop: dropAds,
  restore,
  getOne,
  setDetail,

  setErrorDetail,
  setErrorTable,

  updateSort,
  updatePage,

  uploadCsv,
  uploadCsvDelete,
  csvValidationSucceed,
  csvValidationErrors,
  clearCsvUploadData,
  setCsvFilename,
  setCsvOriginFilename,
  submitCsv,
  submitCsvDelete,

  setStatusTable,
  setStatusCsvUpload,
  setStatusDetail,

  deleteByFilters,
  dropByFilters,
  restoreByFilters,

  changeTab,
  downloadCsv,

  fetchMasterdataByAgency,
  searchMedia,
  searchAdGroup1,
  searchAdGroup2,

  searchMediaAccount,
  searchMediaSideGroup,
  searchMediaSideCampaign,

  cleanStates,

  changeAd,
  changeAdByFilter,
  checkContractedLpoBefore,
  setLpoDataCount,
  getDataRegistrationStatus,
  setStatusGetRegistrationStatus,
  setDataRegistrationStatus,
  cancelRegistrationStatus,
  setOpenRegistrationStatus,
};

export default adManagementActions;
