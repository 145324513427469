import React from 'react';
import { number, func, bool } from 'prop-types';
import { Button } from 'react-bootstrap';
import CountItemSelected from 'views/molecules/CountItemSelected';
import UploadCsvDropdown from 'views/atoms/dropdown/UploadCsvDropdown';
import EbisCustomCheckbox from 'views/atoms/checkbox/EbisCustomCheckbox';
import { REMAIN_ITEMS_REGISTER } from 'domain/ad-management/consts';

function TableControlTabListing(props) {
  const {
    count,
    totalItems,
    remain,
    emptyData,
    onSelectedAllPages,
    onDelete,
    onSelectCsvDelete,
    onSelectCsvRegister,
    isOverLimitUpload,
  } = props;

  const checkboxVariant = count > 0 && count < totalItems ? 'mixed' : undefined;

  const onSelect = (type) => {
    if (type === 'register') {
      onSelectCsvRegister();
    } else if (type === 'delete') {
      onSelectCsvDelete();
    }
  };

  return (
    <div className="d-flex table-control">
      <div className="d-flex table-control__icon">
        <UploadCsvDropdown
          items={[
            {
              key: 'register',
              title: 'CSV一括更新',
            },
            {
              key: 'delete',
              title: 'CSV一括削除',
            },
          ]}
          onSelect={onSelect}
          isOverLimitUpload={isOverLimitUpload}
        />
        {remain >= 0 && (
          <div className="table-control__remain txt-note color-gray-dark">
            ※あと{remain}件登録可能です
          </div>
        )}
      </div>
      <CountItemSelected count={count}>
        <EbisCustomCheckbox
          id="check-all-pages"
          label="ページ跨ぎで全件を選択"
          checked={count > 0}
          disabled={emptyData}
          variant={checkboxVariant}
          onChange={() => onSelectedAllPages(count <= 0)}
        />
        <Button
          variant="secondary"
          size="sm"
          disabled={count === 0}
          onClick={() => onDelete()}
        >
          削除
        </Button>
      </CountItemSelected>
    </div>
  );
}

TableControlTabListing.propTypes = {
  count: number,
  totalItems: number.isRequired,
  remain: number,
  emptyData: bool,
  onSelectedAllPages: func,
  onDelete: func,
  onSelectCsvRegister: func,
  onSelectCsvDelete: func,
  isOverLimitUpload: bool,
};

TableControlTabListing.defaultProps = {
  count: 0,
  remain: REMAIN_ITEMS_REGISTER,
  emptyData: false,
  onSelectedAllPages: () => {},
  onDelete: () => {},
  onSelectCsvRegister: () => {},
  onSelectCsvDelete: () => {},
  isOverLimitUpload: false,
};

export default TableControlTabListing;
