/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import { ReactComponent as TutorialGspImg } from 'assets/images/tutorial/tutorial_gsp.svg';
import { ReactComponent as RedirectButtonIcon } from 'assets/images/tutorial/redirect_button_icon.svg';

import './tutorial-modal.scss';
import { APP_HELP_GSP } from 'services/routes/constants';

const TutorialModalGsp = ({ isShow, onClose }) => {
  return (
    <Modal
      centered
      size="w800"
      show={isShow}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      contentClassName="tutorial-modal"
    >
      <Modal.Header
        bsPrefix="d-flex align-items-center justify-content-center tutorial-gsp__header"
        closeButton
        closeLabel={<i className="fal fa-times" />}
      >
        <Modal.Title className="mr-auto ml-auto text-center">
          <div className="tutorial-gsp__header-title">アドエビスへようこそ</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix="tutorial-modal__container">
        <div className="d-flex flex-column w-100">
          <div className="tutorial-modal__sub-header">
            <div className="tutorial-subheader__type1">
              初期設定をスムーズに進めるためのスターターキットをご用意しました。{' '}
              <br />
              チェックリストに沿って、初期設定を進めていきましょう。
            </div>
          </div>
          <div className="carousel__content d-flex flex-column align-items-center justify-content-center">
            <div className="carousel-type2__img mb-20">
              <TutorialGspImg />
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer bsPrefix="d-flex align-items-center justify-content-center mt-auto">
        <a
          id="gsp_self_tutorial"
          href={APP_HELP_GSP}
          target="_blank"
          rel="noopener noreferrer"
          className="tutorial-modal-gsp__button ebis_button_trigger"
        >
          初期設定チェックリストを開く
          <RedirectButtonIcon />
        </a>
      </Modal.Footer>
    </Modal>
  );
};

TutorialModalGsp.propTypes = {
  isShow: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TutorialModalGsp;
