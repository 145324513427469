/* eslint-disable camelcase */
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import {
  AD_MANAGEMENT_MODE,
  REMAIN_ITEMS_REGISTER,
} from 'domain/ad-management/consts';

import types from 'store/ad-management/types';
import helper from 'store/helper';
import { communicationStatus, getFinalPage } from 'services/utils';

const { IDLE } = communicationStatus;
const FIRST_PAGE = 1;

const initialState = {
  data: {
    detail: {
      item: {},
      errors: [],
    },
    table: {
      list: [],
      errors: [],
    },
    upload: {
      data: {
        needConfirm: false,
        emptyMeasurementType: [],
        changeUrl: [],
        deleteAds: [],
      },
      errors: [],
      filename: null,
      originFilename: null,
    },
    registrationStatus: {
      data: [],
      metadata: {},
    },
  },
  settings: {
    table: {
      remain: REMAIN_ITEMS_REGISTER,
      pagination: {
        totalItems: 0,
        currentPage: FIRST_PAGE,
      },
      sort: {
        field: '',
        direction: '',
      },
    },
    tab: AD_MANAGEMENT_MODE.AD,
  },
  apiStatus: {
    detail: {
      status: IDLE,
    },
    table: {
      status: IDLE,
    },
    upload: {
      status: IDLE,
    },
    registrationStatus: {
      status: IDLE,
      isOpenRegistrationStatus: false,
    },
  },
  lpoRenegotiation: false,
  lpoRegistPageMax: 5,
};

const setDataTable = (state, action) => {
  const { detail, count, remain, changedToLastPage } = action.payload;
  const currentPage = changedToLastPage
    ? getFinalPage(count, TABLE_ROWS_PER_PAGE)
    : state.settings.table.pagination.currentPage;
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        list: detail,
        errors: [],
      },
    },
    settings: {
      ...state.settings,
      table: {
        ...state.settings.table,
        remain,
        pagination: {
          ...state.settings.table.pagination,
          totalItems: count,
          currentPage,
        },
      },
    },
  };
};

const setErrorTable = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      table: {
        ...state.data.table,
        errors: action.payload.errors,
      },
    },
  };
};

const setStatusTable = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    table: {
      ...state.apiStatus.table,
      status: action.payload.status,
    },
  },
});

const updateSort = (state, action) => {
  const { field, direction } = action.payload;

  return {
    ...state,
    settings: {
      ...state.settings,
      table: {
        ...state.settings.table,
        sort: {
          field,
          direction,
        },
        pagination: {
          ...state.settings.table.pagination,
          currentPage: FIRST_PAGE,
        },
      },
    },
  };
};

const updatePage = (state, action) => {
  const { page } = action.payload;

  return {
    ...state,
    settings: {
      ...state.settings,
      table: {
        ...state.settings.table,
        pagination: {
          ...state.settings.table.pagination,
          currentPage: page,
        },
      },
    },
  };
};

const csvValidationSucceed = (state, action) => {
  const {
    needConfirm,
    changeUrl,
    emptyMeasurementType,
    deleteAds,
  } = action.payload.data;
  return {
    ...state,
    data: {
      ...state.data,
      upload: {
        ...state.data.upload,
        data: {
          ...state.data.upload.data,
          needConfirm,
          changeUrl,
          emptyMeasurementType,
          deleteAds,
        },
        errors: initialState.data.upload.errors,
      },
    },
  };
};

const csvValidationErrors = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      upload: {
        ...state.data.upload,
        data: initialState.data.upload.data,
        errors: action.payload.errors,
      },
    },
  };
};

const setCsvFilename = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      upload: {
        ...state.data.upload,
        filename: action.payload.filename,
      },
    },
  };
};

const setCsvOriginFilename = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      upload: {
        ...state.data.upload,
        originFilename: action.payload.originFilename,
      },
    },
  };
};

const changeTab = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    tab: action.payload.tab,
  },
});

const setStatusCsvUpload = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    upload: {
      ...state.apiStatus.upload,
      status: action.payload.status,
    },
  },
});

const setDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      errors: [],
      item: action.payload.data,
    },
  },
});

const setErrorDetail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    detail: {
      ...state.data.detail,
      errors: action.payload.errors,
    },
  },
});

const setStatusDetail = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    detail: {
      ...state.apiStatus.detail,
      status: action.payload.status,
    },
  },
});

const clearCsvUploadData = (state) => ({
  ...state,
  data: {
    ...state.data,
    upload: initialState.data.upload,
  },
});

const cleanStates = (state, action) => ({
  ...initialState,
  settings: {
    ...initialState.settings,
    tab: action.payload.tab,
  },
});

const setLpoRenegotiation = (state, action) => {
  const lpoRenegotiation = action.payload.count > 0;
  return {
    ...state,
    lpoRenegotiation,
    lpoRegistPageMax: action.payload.detail[0].lpo_regist_page_max,
  };
};

const setDataRegistrationStatus = (state, action) => {
  const {
    data: { data, metadata },
  } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      registrationStatus: {
        ...state.data.registrationStatus,
        data,
        metadata,
      },
    },
  };
};

const setStatusGetRegistrationStatus = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    registrationStatus: {
      ...state.apiStatus.registrationStatus,
      status: action.payload.status,
    },
  },
});

const setOpenRegistrationStatus = (state, action) => ({
  ...state,
  apiStatus: {
    ...state.apiStatus,
    registrationStatus: {
      ...state.apiStatus.registrationStatus,
      isOpenRegistrationStatus: action.payload.status,
    },
  },
});

const AdManagementReducer = helper.createReducer(initialState, {
  [types.SET_DATA_TABLE]: setDataTable,
  [types.SET_ERROR_TABLE]: setErrorTable,
  [types.SET_STATUS_TABLE]: setStatusTable,
  [types.UPDATE_SORT]: updateSort,
  [types.UPDATE_PAGE]: updatePage,
  [types.CSV_VALIDATION_SUCCEED]: csvValidationSucceed,
  [types.CSV_VALIDATION_ERRORS]: csvValidationErrors,
  [types.SET_CSV_FILENAME]: setCsvFilename,
  [types.SET_CSV_ORIGIN_FILENAME]: setCsvOriginFilename,
  [types.CHANGE_TAB]: changeTab,
  [types.SET_STATUS_CSV_UPLOAD]: setStatusCsvUpload,
  [types.SET_DETAIL]: setDetail,
  [types.SET_ERROR_DETAIL]: setErrorDetail,
  [types.SET_STATUS_DETAIL]: setStatusDetail,
  [types.CLEAR_CSV_UPLOAD_DATA]: clearCsvUploadData,
  [types.CLEAN_STATES]: cleanStates,
  [types.SET_LPO_DATA_COUNT]: setLpoRenegotiation,
  [types.SET_STATUS_GET_REGISTRATION_STATUS]: setStatusGetRegistrationStatus,
  [types.SET_DATA_REGISTRATION_STATUS]: setDataRegistrationStatus,
  [types.SET_OPEN_REGISTRATION_STATUS]: setOpenRegistrationStatus,
});

export default AdManagementReducer;
