import types from 'store/common/types';

const getData = (payload) => ({
  type: types.GET_COMMON_DATA,
  payload,
});

const setData = (payload) => ({
  type: types.SET_COMMON_DATA,
  payload,
});

const downloadImage = (payload) => ({
  type: types.DOWNLOAD_IMAGE,
  payload,
});

const downloadCsv = (payload) => ({
  type: types.DOWNLOAD_CSV,
  payload,
});

const downloadCsvFail = ({ error, scope }) => ({
  type: types.DOWNLOAD_CSV_ERROR,
  payload: error,
  scope,
});

const updatePeriodWithCompared = ({ period, comparedPeriod }) => ({
  type: types.UPDATE_PERIOD_WITH_COMPARED,
  payload: {
    period,
    comparedPeriod,
  },
});

const changeCurrentUrlLocation = (payload) => ({
  type: types.CHANGE_CURRENT_URL_LOCATION,
  payload,
});

const openTransitionModal = ({
  headerText,
  imgSrc,
  externalLink,
  isNewTab,
}) => ({
  type: types.OPEN_TRANSITION_MODAL,
  payload: { headerText, imgSrc, externalLink, isNewTab },
});

const closeTransitionModal = (transitionToOldScreenDisabled) => ({
  type: types.CLOSE_TRANSITION_MODAL,
  payload: { transitionToOldScreenDisabled },
});

const closeTransitionModalHome = (transitionToOldScreenDisabledHome) => ({
  type: types.CLOSE_TRANSITION_MODAL_HOME,
  payload: { transitionToOldScreenDisabledHome },
});

const closeTransitionModalListing = () => ({
  type: types.CLOSE_TRANSITION_MODAL_LISTING,
});

const clearCache = () => ({
  type: types.CLEAR_CACHE,
});

const toggleDownload = (payload) => ({
  type: types.TOGGLE_DOWNLOAD,
  payload,
});

const setDownloadNotify = (content) => ({
  type: types.SET_DOWNLOAD_NOTIFY,
  payload: content,
});

const setDownloadStatus = (payload) => ({
  type: types.SET_DOWNLOAD_STATUS,
  payload,
});

const changeTutorialStatus = (data) => ({
  type: types.CHANGE_TUTORIAL_STATUS,
  payload: data,
});

const toggleTutorialModalStatus = (bool) => ({
  type: types.TOGGLE_MODAL_STATUS,
  payload: bool,
});

const checkUrl = (url, callback) => ({
  type: types.CHECK_URL,
  payload: { url, callback },
});

export default {
  getData,
  setData,
  downloadImage,
  downloadCsv,
  downloadCsvFail,
  toggleDownload,
  changeCurrentUrlLocation,
  updatePeriodWithCompared,
  openTransitionModal,
  closeTransitionModal,
  closeTransitionModalHome,
  closeTransitionModalListing,
  clearCache,
  setDownloadNotify,
  setDownloadStatus,
  changeTutorialStatus,
  toggleTutorialModalStatus,
  checkUrl,
};
