/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect, Suspense } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { APP_HELP_GSP, EXTERNAL, INTERNAL } from 'services/routes/constants';
import { selectTutorial } from 'domain/topbar/TutorialConfigs';

import { commonActions } from 'store/common';
import {
  getTutorialData,
  getUserId,
  getGSPContractFlag,
} from 'store/auth/selectors';

import SupportConfig from 'domain/topbar/SupportConfig';

import { retry } from 'services/utils';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';

import IconDropdown from 'views/atoms/dropdown/IconDropdown';
import ExternalTransitionWrapper from 'services/routes/ExternalTransitionWrapper';
import EbisPopover from 'views/atoms/popover/EbisPopover';
import TutorialModalGsp from 'views/molecules/modal/TutorialModal/TutorialModalGsp';

import iconAnotherTab from 'assets/images/icon_another_tab.png';
import InsertBreakLine from './InsertBreakLine';

const TutorialModalContainer = React.lazy(() =>
  retry(() => import('views/molecules/modal/TutorialModal'))
);

const Support = () => {
  const dispatch = useDispatch();
  const { isCapiSingleContract } = useCheckPermissionCapi();
  const isGSPContract = useSelector(getGSPContractFlag, shallowEqual);

  // Only show again when re-login
  const userId = useSelector(getUserId, shallowEqual);

  const isViewedThisSession =
    sessionStorage.getItem(
      `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.tutorial.${userId}`
    ) || 'false';

  const tutorialData = useSelector(getTutorialData, shallowEqual);
  const { type: tutorial, isCompleted, isGspCompleted } = tutorialData;

  const [modalShow, toggleModalShow] = useState(false);
  const [tutorialType, changeTutorialType] = useState(null);
  const [isHighlight, toggleHighlight] = useState(false);
  const [isShowGspTutorialModal, setShowGspTutorialModal] = useState(false);

  useEffect(() => {
    if (isHighlight)
      document.getElementById('root').classList.add('highlight-is-active');
    else if (!isHighlight)
      document.getElementById('root').classList.remove('highlight-is-active');
  }, [isHighlight]);

  const handleToggleModal = useCallback((bool) => {
    toggleModalShow(() => bool);
  }, []);

  const { topMenu, subMenu } = SupportConfig;

  const handleTutorialModal = useCallback((type) => {
    changeTutorialType(type);
    toggleModalShow(true);
  }, []);

  useEffect(() => {
    if (
      tutorial &&
      !isCompleted &&
      isViewedThisSession === 'false' &&
      !isCapiSingleContract
    ) {
      changeTutorialType(selectTutorial.new);
      toggleModalShow(true);
      sessionStorage.setItem(
        `${process.env.REACT_APP_LOCAL_STORAGE_KEY}.tutorial.${userId}`,
        'true'
      );
    }
  }, [
    isCompleted,
    isViewedThisSession,
    tutorial,
    userId,
    isCapiSingleContract,
  ]);

  const handleCloseTutorialGsp = () => {
    toggleModalShow(false);
    setShowGspTutorialModal(false);
    toggleHighlight(true);
  };

  const handleCloseHighlightBtn = (e) => {
    e.stopPropagation();
    toggleHighlight(false);
    if (isGSPContract && !isGspCompleted) {
      dispatch(
        commonActions.changeTutorialStatus({
          isShow: true,
          tutorial_name: 'gsp_initial',
          step_name: 'overall',
        })
      );
      if (!isCompleted) {
        dispatch(commonActions.changeTutorialStatus({ isShow: true }));
      }
    }
  };

  // This resolve the conflicts when there are two modal on start
  // Hide the news modal when showing tutorial
  useEffect(() => {
    if (modalShow || isHighlight) {
      dispatch(commonActions.toggleTutorialModalStatus(true));
    } else if (!modalShow && !isHighlight) {
      dispatch(commonActions.toggleTutorialModalStatus(false));
    }
  }, [modalShow, dispatch, isHighlight]);

  useEffect(() => {
    if (isGSPContract && !isGspCompleted) {
      toggleModalShow(false);
      setShowGspTutorialModal(true);
    }
  }, [isGspCompleted, isGSPContract]);

  return (
    <>
      {modalShow && !isShowGspTutorialModal && (
        <Suspense fallback={<></>}>
          <TutorialModalContainer
            isShow={modalShow}
            isCompleted={isCompleted}
            setShow={handleToggleModal}
            changeType={handleTutorialModal}
            type={tutorialType}
            callback={() => tutorial && toggleHighlight(true)}
          />
        </Suspense>
      )}
      {isShowGspTutorialModal && (
        <Suspense fallback={<></>}>
          <TutorialModalGsp
            isShow={isShowGspTutorialModal}
            onClose={handleCloseTutorialGsp}
          />
        </Suspense>
      )}
      <Dropdown>
        <Dropdown.Toggle as={IconDropdown} disabled={isHighlight}>
          {isHighlight ? (
            <EbisPopover
              placement="bottom"
              trigger=""
              defaultShow
              closeBtn={handleCloseHighlightBtn}
              content={
                isGSPContract ? (
                  <div style={{ color: '#000' }}>
                    初期設定チェックリストはここから再度
                    <br />
                    ご覧いただけます。
                  </div>
                ) : (
                  <div>
                    活用事例やサポートメニューを格納しているこちらの
                    <strong>ヘルプ</strong>をご活用ください。
                    <br />
                    チュートリアルもここから再度ご覧いただけます。
                  </div>
                )
              }
            >
              <div className="d-flex justify-content-center">
                <span className="icon-svg icon-support icon-svg__w36 highlight" />
              </div>
            </EbisPopover>
          ) : (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip className="tooltip-common">ヘルプ</Tooltip>}
            >
              <div className="d-flex justify-content-center">
                <span className="icon-svg icon-support icon-svg__w36" />
              </div>
            </OverlayTrigger>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          alignRight="true"
          className="dropdown-menu-padding dropdown-menu-shadow nav-help"
        >
          {topMenu?.map(
            ({ text, url, order, tutorialModal, isSupportSite }) => {
              if (tutorialModal) {
                return isCapiSingleContract ? (
                  <></>
                ) : (
                  <Dropdown.Item
                    key={order}
                    onClick={() => handleTutorialModal(tutorialModal)}
                  >
                    {text}
                  </Dropdown.Item>
                );
              }
              return (
                <a
                  id={url === APP_HELP_GSP ? "gsp_self_help_menu" : 'help_menu'}
                  key={order}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="txt-decoration-none"
                >
                  <Dropdown.Item
                    key={order}
                    as="div"
                    className="d-flex justify-content-between"
                  >
                    <div>{text}</div>
                    {isSupportSite && (
                      <div>
                        <img
                          src={iconAnotherTab}
                          alt="icon-another-tab"
                          width="13px"
                          height="13px"
                        />
                      </div>
                    )}
                  </Dropdown.Item>
                </a>
              );
            }
          )}
          <div className="dropdown-submenu bottom">
            <Dropdown.Header>お問い合わせ</Dropdown.Header>
            {subMenu?.map(
              ({ text, url, order, newTab, useImg, isSupportSite }) => {
                if (isSupportSite) {
                  return isCapiSingleContract ? (
                    <></>
                  ) : (
                    <a
                      key={order}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="txt-decoration-none"
                    >
                      <Dropdown.Item
                        key={order}
                        as="div"
                        className="d-flex justify-content-between"
                      >
                        <div className="nav-help_lh">
                          <InsertBreakLine text={text} />
                        </div>
                        {useImg ? (
                          <div>
                            <img
                              src={iconAnotherTab}
                              alt="icon-another-tab"
                              width="13px"
                              height="13px"
                              className="ml-1"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </Dropdown.Item>
                    </a>
                  );
                }
                return (
                  <ExternalTransitionWrapper
                    key={order}
                    link={url}
                    type={newTab ? EXTERNAL : INTERNAL}
                    newTab={newTab}
                  >
                    <Dropdown.Item key={order} as="div">
                      <InsertBreakLine text={text} />
                    </Dropdown.Item>
                  </ExternalTransitionWrapper>
                );
              }
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default React.memo(Support);
