import helper from 'store/helper';
import types from './types';

const updateLatestActivity = (data) =>
  helper.createAction(types.UPDATE_LATEST_ACTIVITY, data);
const updateAcquisitionMediaClickId = (data) =>
  helper.createAction(types.UPDATE_ACQUISITION_MEDIA_CICK_ID, data);
const updateTutorialStatus = (bool) =>
  helper.createAction(types.UPDATE_TUTORIAL_STATUS, bool);
const updateTutorialGspStatus = (bool) =>
  helper.createAction(types.UPDATE_TUTORIAL_GSP_STATUS, bool);
const logout = () => helper.createAction(types.LOGOUT);

export {
  updateLatestActivity,
  updateTutorialStatus,
  logout,
  updateAcquisitionMediaClickId,
  updateTutorialGspStatus,
};
