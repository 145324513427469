import helper from 'store/helper';
import moment from 'moment';
import types from './types';

export const initialState = {
  returnTo: null,
  accessToken: null,
  refreshToken: null,
  isLoggedIn: null,
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed' | 'token_expired' ,
  error: null, // string | null,
  account: {
    accountId: null,
    accountStr: null,
    clientName: null,
    clientNameKana: null,
    clientSubName: null,
    clientSubNameKana: null,
    adCvCostMode: null,
    measurementHosts: null,
    acquisitionMediaClickId: false,
  },
  permissions: null,
  email: null,
  chargeName: null,
  companyName: null,
  userId: null,
  agencyId: null,
  agencyName: null,
  agentFlag: false,
  tutorial: {
    type: null,
    isCompleted: null,
    isGspCompleted: null,
  },
  latestActivity: moment(),
  maintenance: null,
};

const updateTutorialStatus = (state, action) => {
  return {
    ...state,
    tutorial: {
      ...state.tutorial,
      isCompleted: action.payload,
    },
  };
};

const updateTutorialStatusGsp = (state, action) => {
  return {
    ...state,
    tutorial: {
      ...state.tutorial,
      isGspCompleted: action.payload,
    },
  };
};

const updateLatestActivity = (state, action) => {
  const { latestActivity } = action.payload;
  return {
    ...state,
    latestActivity,
  };
};

const updateUser = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const updateAcquisitionMediaClickId = (state, action) => {
  const { acquisitionMediaClickId } = action.payload;
  return {
    ...state,
    acquisitionMediaClickId,
  };
};

const logout = () => {
  return initialState;
};

const AuthReducer = helper.createReducer(initialState, {
  [types.UPDATE_LATEST_ACTIVITY]: updateLatestActivity,
  [types.UPDATE_TUTORIAL_STATUS]: updateTutorialStatus,
  [types.UPDATE_TUTORIAL_GSP_STATUS]: updateTutorialStatusGsp,
  [types.UPDATE_USER]: updateUser,
  [types.UPDATE_ACQUISITION_MEDIA_CICK_ID]: updateAcquisitionMediaClickId,
  [types.LOGOUT]: logout,
});

export default AuthReducer;
