import { shape, bool, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import DisplayItemsToggle from 'views/atoms/DisplayItemsToggle';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import FilterToggle from 'views/atoms/FilterToggle';
import AdManagementDownloadFileContainer from 'views/molecules/commonbar/components/AdManagementDownloadFileContainer';
import AdManagementDisplayItemsModalContainer from 'views/organism/DisplayItemsModal/AdManagementDisplayItemsModalContainer';
import AdManagementFilterContainer from 'views/organism/FilterForm/AdManagementFilterContainer';
import RegistrationStatus from 'views/molecules/commonbar/components/RegistrationStatus';

function AdManagementCommonBar(props) {
  const { title, tooltip, allowedScreen, hasFilters } = props;

  const [activeFunc, setActiveFunc] = useState(null);

  const isShowFilter = activeFunc === 'filter';

  const setShowFilter = useCallback((show) => {
    if (show) {
      setActiveFunc('filter');
    } else {
      setActiveFunc('');
    }
  }, []);

  const onFilterToggle = useCallback((funcName) => {
    if (funcName) {
      setActiveFunc('filter');
    } else {
      setActiveFunc(null);
    }
  }, []);

  const toggleActiveFunc = useCallback((funcName) => {
    if (funcName) {
      setActiveFunc(funcName);
    } else {
      setActiveFunc(null);
    }
  }, []);

  const hideDisplayItemsModal = () => {
    setActiveFunc('');
  };

  return (
    <div className="clearfix common-bar">
      <Navbar>
        <Navbar.Brand as="div" className="mr-auto">
          <div className="page d-flex align-items-center">
            {title}
            <DragonBall variant="title" icon="far fa-question-circle" stayOpen>
              {tooltip}
            </DragonBall>
          </div>
        </Navbar.Brand>
        {allowedScreen && (
          <Nav>
            <DisplayItemsToggle
              variant="sub-icon-settings"
              onToggle={toggleActiveFunc}
              isActive={activeFunc === 'displayItems'}
            />
            <FilterToggle
              showFilter={isShowFilter}
              variant="sub-icon-settings"
              filters={{}}
              setShowFilter={setShowFilter}
              onActive={onFilterToggle}
              filterChanged={hasFilters}
              isActive={isShowFilter}
            />
            <AdManagementDownloadFileContainer
              toggleActiveFunc={toggleActiveFunc}
            />
            <RegistrationStatus />
          </Nav>
        )}
      </Navbar>
      <div className={isShowFilter ? 'd-block' : 'd-none'}>
        <AdManagementFilterContainer />
      </div>
      <AdManagementDisplayItemsModalContainer
        show={activeFunc === 'displayItems'}
        onHide={hideDisplayItemsModal}
      />
    </div>
  );
}

AdManagementCommonBar.propTypes = {
  title: string.isRequired,
  tooltip: shape({}),
  allowedScreen: bool,
  hasFilters: bool,
};

AdManagementCommonBar.defaultProps = {
  tooltip: {},
  allowedScreen: false,
  hasFilters: false,
};

export default AdManagementCommonBar;
