import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { func, string, shape, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Dropdown, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { formatNumber } from 'domain/utils';
import {
  NEW,
  START,
  RUNNING,
  RETRY,
  COMPLETED,
  TIMEOUT,
  FAILED,
  CANCELLED,
} from 'domain/ad-management/consts';
import { getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';
import useHoverTooltip from 'services/custom-hooks/useHoverTooltip';
import loggerActions from 'store/logger/actions';
import adManagementActions from 'store/ad-management/actions';
import adManagementSelectors from 'store/ad-management/selectors';
import Spinner from 'views/atoms/loader/Spinner';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import { IconCautionYellow } from 'views/atoms/icon/SvgIcon';
import './registrationstatus.scss';

const getAdType = (type) => {
  let adType = '';
  if (type === 'ebis') {
    adType = 'AD広告';
  } else if (type === 'lpo') {
    adType = 'LPO広告';
  } else if (type === 'listing') {
    adType = 'リスティング連携広告';
  }

  return adType;
};

const StopButton = ({ onCancelClick }) => {
  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        className="m-0 btn-stop"
        onClick={onCancelClick}
      >
        中止
      </Button>
    </>
  );
};
StopButton.propTypes = {
  onCancelClick: func.isRequired,
};

const Status = ({ status, onCancelClick }) => {
  return (
    <>
      {status === NEW && (
        <>
          <div className="status-new">登録待ち</div>
          <StopButton onCancelClick={onCancelClick} />
        </>
      )}
      {[START, RUNNING, RETRY].includes(status) && (
        <div className="status-registering">
          登録中
          <Spinner />
        </div>
      )}
      {status === COMPLETED && <div className="status-completed">完了</div>}
      {[TIMEOUT, FAILED].includes(status) && (
        <>
          <div className="status-error">
            <DragonBall
              svg={<IconCautionYellow />}
              placement="top"
              variant="warning"
            >
              <>
                処理中にエラーが発生しました。もう一度アップロードをやり直してください。それでもエラーが解消しない場合はお問い合わせください。
              </>
            </DragonBall>
            <div>エラー</div>
          </div>
          <StopButton onCancelClick={onCancelClick} />
        </>
      )}
      {status === CANCELLED && <div className="status-cancelled">中止</div>}
    </>
  );
};

Status.propTypes = {
  status: string,
  onCancelClick: func.isRequired,
};

Status.defaultProps = {
  status: '',
};

const RegistrationStatusDetailTooltip = ({ item, hidden }) => {
  return (
    <div className="registration-status__detail__tooltip" hidden={hidden}>
      <div>{item.file_name}</div>
    </div>
  );
};

RegistrationStatusDetailTooltip.propTypes = {
  item: shape({}).isRequired,
  hidden: bool.isRequired,
};

const RegistrationStatusDetail = ({ item, onCancelClick }) => {
  const detailRef = useRef();
  const fileNameRef = useRef();
  const scrollWidth = fileNameRef?.current?.scrollWidth || 202;
  const offsetWidth = fileNameRef?.current?.offsetWidth || 202;

  useHoverTooltip(
    detailRef,
    <RegistrationStatusDetailTooltip
      item={item}
      hidden={scrollWidth <= offsetWidth}
    />
  );

  return (
    <div className="registration-status__detail">
      <div className="registration-status__detail__left" ref={detailRef}>
        <div className="detail__file-name" ref={fileNameRef}>
          {item.file_name}
        </div>
        <div className="detail__upload-start-time">
          アップロード日時：{item.upload_start_time}
        </div>
        <div className="detail__ad-type">
          {getAdType(item.ad_type)}・{formatNumber(item.registered_records)}件
        </div>
      </div>
      <div className="registration-status__detail__right">
        <Status
          status={item.status}
          onCancelClick={onCancelClick(item.token)}
        />
      </div>
    </div>
  );
};

RegistrationStatusDetail.propTypes = {
  item: shape({}).isRequired,
  onCancelClick: func.isRequired,
};

function RegistrationStatus() {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const scrollbarWrapperRef = useRef(null);
  const [iconToggle, toggleIcon] = useState(false);

  const { data, metadata, statusRegistrationStatus } = useSelector(
    adManagementSelectors.getStatesRegistrationStatus,
    shallowEqual
  );

  const { isOpenRegistrationStatus } = useSelector(
    adManagementSelectors.isOpenRegistrationStatus,
    shallowEqual
  );

  const isDisabled = useMemo(() => {
    return data.length === 0;
  }, [data.length]);

  const registrationStatusData = useMemo(() => {
    if (isEmpty(data)) return [];

    return data;
  }, [data]);

  const isRegistering = useMemo(() => {
    return metadata.waiting + metadata.current_upload + metadata.error >= 1;
  }, [metadata.current_upload, metadata.error, metadata.waiting]);

  const isCompleted = useMemo(() => {
    return metadata.completed + metadata.cancelled > 0;
  }, [metadata.cancelled, metadata.completed]);

  const IconDropdown = React.forwardRef(({ children, onClick, disabled }) => {
    return (
      <div
        role="button"
        aria-hidden
        onClick={(e) => {
          e.preventDefault();
          if (!disabled) {
            onClick(e);
            toggleIcon((prev) => !prev);
          }
        }}
      >
        {children}
      </div>
    );
  });

  const iconClass = classNames({
    'icon-svg': true,
    'icon-registration-status': true,
    'icon-registration-status-completed': true,
    fafilter: isRegistering,
  });
  const loaderClass = classNames({
    'modal-loader-registration-status': true,
  });

  const handleResponse = ({ isError, errors }) => {
    if (!isError) {
      dispatch(adManagementActions.getDataRegistrationStatus());
    } else if (errors?.length > 0) {
      const message = getErrorMessageByCode(errors[0], messageError);
      dispatch(
        loggerActions.logConfirmModal({
          title: '処理が中止できませんでした',
          content: message,
          callback: () => {},
        })
      );
    }
  };
  const onCancelClick = useCallback(
    (id) => () => {
      dispatch(
        adManagementActions.cancelRegistrationStatus(id, true, handleResponse)
      );
    },
    [dispatch]
  );

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleIcon(false);
      adManagementActions.setOpenRegistrationStatus(false);
      dispatch(adManagementActions.setOpenRegistrationStatus(false));
    }
  };

  useEffect(() => {
    if (isOpenRegistrationStatus) {
      toggleIcon(true);
    }
  }, [isOpenRegistrationStatus]);

  useEffect(() => {
    // Add event listener for detecting outside click
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (iconToggle) {
      dispatch(adManagementActions.getDataRegistrationStatus());
    }
  }, [dispatch, iconToggle]);

  return (
    <>
      {isDisabled ? (
        <>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="bottom"
            overlay={
              <Tooltip
                className="tooltip-registration-status"
                variant="disabled"
                placement="bottom"
              >
                現在CSV一括登録中の処理はありません
              </Tooltip>
            }
          >
            <span className="d-inline-block">
              <Dropdown
                as="div"
                role="button"
                bsPrefix="dropdown nav-link nav-link--icon"
                disabled={isDisabled}
                show={iconToggle}
                ref={ref}
              >
                <Dropdown.Toggle as={IconDropdown}>
                  <div className="d-flex justify-content-center">
                    <span className={iconClass} />
                  </div>
                  <div>登録状況</div>
                </Dropdown.Toggle>
              </Dropdown>
            </span>
          </OverlayTrigger>
        </>
      ) : (
        <>
          <Dropdown
            as="div"
            role="button"
            bsPrefix="dropdown nav-link nav-link--icon"
            className={`${iconToggle ? 'active' : ''}`}
            disabled={isDisabled}
            show={iconToggle}
            ref={ref}
          >
            <Dropdown.Toggle as={IconDropdown}>
              <div className="d-flex justify-content-center">
                <span className={iconClass} />
              </div>
              <div>登録状況</div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              alignRight="true"
              className="dropdown-menu-shadow registration-status"
            >
              <Dropdown.Header
                as="div"
                bsPrefix="registration-status__header"
                className="align-items-center"
              >
                <p className="registration-status__title">
                  登録状況（CSV一括登録）
                </p>
                <p className="registration-status__note">
                  ※処理が完了して1時間経った履歴はリストから消えます
                </p>
              </Dropdown.Header>
              {/* <DataSyncLoader
                isLoading={statusRegistrationStatus}
                className={loaderClass}
              > */}
              <div
                className="registration-status__content"
                ref={scrollbarWrapperRef}
              >
                <ScrollbarWrapper
                  ref={scrollbarWrapperRef}
                  maxContent={355}
                  sizeScroll={5}
                  alignScroll={5}
                >
                  {registrationStatusData && (
                    <>
                      {registrationStatusData.map((item) => {
                        return (
                          <RegistrationStatusDetail
                            item={item}
                            onCancelClick={onCancelClick}
                          />
                        );
                      })}
                    </>
                  )}
                </ScrollbarWrapper>
              </div>
              {/* </DataSyncLoader> */}
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
    </>
  );
}

export default RegistrationStatus;
