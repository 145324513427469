import {
  SUPPORT_SITE,
  SUCCESS_STORY_SITE,
  APP_HELP_GSP,
  CONTACT_DEMAND_MANAGEMENT,
  CONTACT_MANAGEMENT,
  APP_HELP_FOR_NEW_STAFF,
} from 'services/routes/constants';

import { CHOOSE_STEPS } from 'domain/topbar/TutorialConfigs';

const SupportConfig = {
  topMenu: [
    {
      order: 1,
      text: 'サポートサイト',
      url: SUPPORT_SITE,
      newTab: true,
      tutorialModal: null,
      isSupportSite: true,
    },
    {
      order: 2,
      text: '活用ノウハウ',
      url: SUCCESS_STORY_SITE,
      newTab: true,
      tutorialModal: null,
      isSupportSite: true,
    },
    {
      order: 3,
      text: '初期設定チェックリスト',
      url: APP_HELP_GSP,
      newTab: true,
      useImg: true,
      isSupportSite: true,
    },
    {
      order: 4,
      text: 'チュートリアル',
      url: null,
      newtab: null,
      tutorialModal: CHOOSE_STEPS,
    },
  ],
  subMenu: [
    {
      order: 5,
      text: '設定/仕様について',
      url: CONTACT_MANAGEMENT,
      newTab: false,
      useImg: false,
      isSupportSite: false,
    },
    {
      order: 6,
      text: 'ご意見やご要望について',
      url: CONTACT_DEMAND_MANAGEMENT,
      newTab: false,
      useImg: false,
      isSupportSite: false,
    },
    {
      order: 7,
      text: 'アドエビスの運用を\n引き継ぐ方へ',
      url: APP_HELP_FOR_NEW_STAFF,
      newTab: false,
      useImg: true,
      isSupportSite: true,
    },
  ],
};

export default SupportConfig;
