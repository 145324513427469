// create authorization_code
const LOGOUT = 'auth/LOGOUT';
const UPDATE_LATEST_ACTIVITY = 'auth/UPDATE_LATEST_ACTIVITY';
const UPDATE_TUTORIAL_STATUS = 'auth/UPDATE_TUTORIAL_STATUS';
const UPDATE_TUTORIAL_GSP_STATUS = 'auth/UPDATE_TUTORIAL_GSP_STATUS';
const UPDATE_USER = 'auth/UPDATE_USER';
const UPDATE_ACQUISITION_MEDIA_CICK_ID =
  'auth/UPDATE_ACQUISITION_MEDIA_CICK_ID';

export default {
  UPDATE_LATEST_ACTIVITY,
  LOGOUT,
  UPDATE_TUTORIAL_STATUS,
  UPDATE_USER,
  UPDATE_ACQUISITION_MEDIA_CICK_ID,
  UPDATE_TUTORIAL_GSP_STATUS,
};
