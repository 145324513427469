import React from 'react';
import { arrayOf, func, string, shape, bool } from 'prop-types';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TableTooltips from 'services/common/TableTooltips';

const DropdownItem = ({ item, mode, lpoPermission, isOverLimitUpload }) => {
  let dropdownItem = '';
  if (item.key === 'register' && mode === 'lpo' && !lpoPermission) {
    dropdownItem = (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={
          <Tooltip variant="disabled" placement="bottom">
            {TableTooltips.lpoCancelledTooltipMassage}
          </Tooltip>
        }
      >
        <div>
          <Dropdown.Item key={item.key} eventKey={item.key} disabled>
            {item.title}
          </Dropdown.Item>
        </div>
      </OverlayTrigger>
    );
  } else if (isOverLimitUpload && item.key === 'register') {
    dropdownItem = (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip variant="over-limit" placement="top">
            {TableTooltips.overLimitUploadTooltipMassage}
          </Tooltip>
        }
      >
        <div>
          <Dropdown.Item key={item.key} eventKey={item.key} disabled>
            {item.title}
          </Dropdown.Item>
        </div>
      </OverlayTrigger>
    );
  } else {
    dropdownItem = (
      <Dropdown.Item key={item.key} eventKey={item.key}>
        {item.title}
      </Dropdown.Item>
    );
  }
  return <>{dropdownItem}</>;
};

DropdownItem.propTypes = {
  item: shape({}).isRequired,
  lpoPermission: bool,
  mode: string,
  isOverLimitUpload: bool,
};

DropdownItem.defaultProps = {
  lpoPermission: false,
  mode: '',
  isOverLimitUpload: false,
};

function UploadCsvDropdown(props) {
  const { items, onSelect, lpoPermission, mode, isOverLimitUpload } = props;

  return (
    <Dropdown className="d-inline" onSelect={(value) => onSelect(value)}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip className="tooltip-common">CSV一括操作</Tooltip>}
      >
        <Dropdown.Toggle
          bsPrefix="btn"
          variant="secondary"
          size="sm"
          className="btn-icon--no-text rounded-circle"
        >
          <i className="far fa-arrow-from-bottom fs-18" />
        </Dropdown.Toggle>
      </OverlayTrigger>
      <Dropdown.Menu className="dropdown-menu-icon dropdown-menu-padding dropdown-menu-shadow">
        {items.map((item) => (
          <>
            <DropdownItem
              item={item}
              lpoPermission={lpoPermission}
              mode={mode}
              isOverLimitUpload={isOverLimitUpload}
            />
          </>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

UploadCsvDropdown.propTypes = {
  onSelect: func,
  items: arrayOf(shape({ key: string, title: string })),
  lpoPermission: bool,
  mode: string,
  isOverLimitUpload: bool,
};

UploadCsvDropdown.defaultProps = {
  onSelect: () => {},
  items: [],
  lpoPermission: false,
  mode: '',
  isOverLimitUpload: false,
};

export default UploadCsvDropdown;
